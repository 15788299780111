<template>
  <main class="page blog-page">
    <div class="blog-page__categories container-padding">
      <span
        class="blog-page__category"
        :class="{ 'blog-page__category--active': !$store.state.blog_page.activeCategories }"
        @click="updateCategories(null)"
        >Все</span
      >
      <span
        class="blog-page__category"
        :class="{ 'blog-page__category--active': $store.state.blog_page.activeCategories === category.id }"
        v-for="category in $store.state.blog_page.categories"
        :key="category.id"
        @click="updateCategories(category.id)"
      >
        {{ category.title }}
      </span>
    </div>
    <div class="blog-page__inner container-padding" :class="{ 'blog-page__loading': loading }">
      <div class="blog-page__body" v-for="(items, idx) in $store.state.blog_page.blogs" :key="idx">
        <BlogCard v-for="(blog, bI) in items" :key="bI" :blog="blog" />
      </div>
      <ObserverComponent @intersect="loadMore" />
    </div>
  </main>
</template>

<script>
import BlogCard from "./components/BlogCard.vue";
import ObserverComponent from "components/ObserverComponent.vue";
import BLOG_PAGINATE from "@/graphql/pages/landing/articles_paginate.graphql";

function mappedBlogs(array) {
  let size = 13; //размер подмассива
  let subarray = [];
  for (let i = 0; i < Math.ceil(array.length / size); i++) {
    subarray[i] = array.slice(i * size, i * size + size);
  }

  return subarray;
}
export default {
  name: "BlogsPage",
  components: {
    BlogCard,
    ObserverComponent,
  },
  async asyncData({ apollo, store }) {
    await apollo.clients.landing
      .query({
        query: BLOG_PAGINATE,
        variables: {
          page: store.state.blog_page.page,
          first: store.state.blog_page.first,
          categories: undefined,
        },
        fetchPolicy: "network-only",
      })
      .then(({ data }) => {
        store.state.blog_page.categories = data.article_categories;
        store.state.blog_page.blogs = mappedBlogs(data.articles_paginate.data);
        store.state.blog_page.total = data.articles_paginate.paginatorInfo.total;
      });
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async loadMore() {
      if (this.$store.state.blog_page.first < this.$store.state.blog_page.total) {
        this.$store.state.blog_page.loader = true;
        this.$store.state.blog_page.first += 13;
        await this.get();
        this.$store.state.blog_page.loader = false;
      }
    },
    updateCategories(id) {
      this.$store.state.blog_page.activeCategories = id;
      this.get();
    },
    get() {
      this.loading = true;
      this.$apollo.provider.clients.landing
        .query({
          query: BLOG_PAGINATE,
          variables: {
            page: this.$store.state.blog_page.page,
            first: this.$store.state.blog_page.first,
            categories: this.$store.state.blog_page.activeCategories
              ? [this.$store.state.blog_page.activeCategories]
              : undefined,
          },
          fetchPolicy: "network-only",
        })
        .then(({ data }) => {
          this.$store.state.blog_page.categories = data.article_categories;
          this.$store.state.blog_page.blogs = this.mappedBlogs(data.articles_paginate.data);
          this.$store.state.blog_page.total = data.articles_paginate.paginatorInfo.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mappedBlogs,
  },
};
</script>

<style lang="stylus">
.blog-page {

  &__loading {
    opacity 0.7
  }

  &__categories {
    display flex
    gap 10px
    margin-bottom 32px
  }

  &__category {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    font-size: 0.875em;
    font-weight: 400;
    line-height: 14px;
    border-radius: 8px;
    background: var(--gray-30);
    width: -moz-fit-content;
    width: fit-content;
    cursor pointer
    transition var(--transition)

    &:active {
      transform scale(0.95)
    }

    &--active {
      background var(--main)
      color var(--white)
    }
  }


  &__inner {
    display flex
    flex-direction column
    gap 16px
  }

  &__body {
    display grid
    gap 16px
    //row-gap 60px
    grid-template-columns repeat(12,1fr)
    grid-template-rows 337px 337px 382px 360px 373px 373px

    +below(1024px) {
      grid-template-columns: 1fr;
      grid-template-rows: 340px;
    }

    .blog-card {
      &:nth-child(1) {
        grid-column span 6
        grid-row span 2

        +below(1024px) {
          grid-column span 1
          grid-row span 1
        }
      }
      &:nth-child(2),
      &:nth-child(3) {
        grid-column span 6
        +below(1024px) {
          grid-column span 1
          grid-row span 1
        }
      }

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7){
        grid-column span 3
        +below(1024px) {
          grid-column span 1
          grid-row span 1
        }
      }
      &:nth-child(8),
      &:nth-child(9),
      &:nth-child(10){
        grid-column span 4
        +below(1024px) {
          grid-column span 1
          grid-row span 1
        }
      }
      &:nth-child(11),
      &:nth-child(12){
        grid-column span 6
        +below(1024px) {
          grid-column span 1
          grid-row span 1
        }
      }
      &:nth-child(13){
        grid-column span 6
        grid-row span 2
        grid-area: 5 / 7 / 7 / 13;
        +below(1024px) {
          grid-column span 1
          grid-row span 1
        }
      }
    }
  }
}
</style>
