<template>
  <router-link :to="{ name: 'blog-item', params: { id: blog.id } }" class="blog-card">
    <ImgComponent :img="blog.preview_img" />
    <span class="blog-card__tag" v-for="(category, i) in blog.categories" :key="i">{{ category.title }}</span>
    <span class="blog-card__title">{{ blog.title }}</span>
    <span class="blog-card__subtitle"> {{ blog.description }} </span>
  </router-link>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "blogComponent",
  props: {
    blog: {
      type: Object,
    },
  },

  components: {
    ImgComponent,
  },
};
</script>

<style lang="stylus">
.blog-card {
  display flex
  flex-direction column
  gap 8px
  padding 22px
  border-radius: 16px;
  background: #FAFAFA;

  img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    margin-bottom 8px
  }

  &__tag {
    display flex
    justify-content: center;
    align-items: center;
    padding 4px 8px
    color: var(--rose-800, #4F0733);
    font-size: 0.875em;
    font-weight: 400;
    line-height: 14px;
    border-radius: 8px;
    border: 1px solid var(--rose-50, #FBE9F4);
    background: var(--rose-50, #FBE9F4);
    width: fit-content;
  }

  &__title {
    color: var(--theme-body, #292929);
    font-size: 1.25em;
    font-weight: 500;
    line-height: 24px;
  }

  &__subtitle {
    color: var(--theme-body, #292929);
    font-size: 1em;
    font-weight: 300;
    line-height: 22px;
  }
}
</style>
